export const HEADERS = [
  {
    value: "id",
    text: "ID",
    width: 10,
  },
  {
    value: "source",
    text: "Source",
    width: 40,
  },
  {
    value: "email",
    text: "Email",
    width: 20,
  },
  {
    value: "insertedAt",
    text: "Created At",
    togglable: true,
    visible: true,
    width: 150,
  },
  {
    value: "updatedAt",
    text: "Updated At",
    togglable: true,
    visible: true,
    width: 150,
  },
  {
    value: "actions",
    text: "",
    width: 40,
  },
]
