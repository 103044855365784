import { SuppressionSourcesOptions } from "@evercam/shared/constants/emailing"

export const FILTERS = {
  id: {
    component: "TextFieldSearchFilter",
  },
  email: {
    component: "TextFieldSearchFilter",
  },
  source: {
    component: "SelectSearchFilter",
    attributes: {
      items: [{ name: "All", value: "" }, ...SuppressionSourcesOptions],
      value: "",
    },
  },
}
